/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    img: "img",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2013/02/hogar1.png"
  }, React.createElement(_components.img, {
    src: "http://litox9.files.wordpress.com/2013/02/hogar1.png?w=150",
    alt: "icono_hogar"
  })), "Hoy voy a hablar sobre un ejemplo concreto y muy local que puede dar una idea del concepto tradicional de hogar, el de comunidad. Vivo en un pueblo de los alrededores de Valencia que por las fiestas del pueblo como en muchos otros sitios las comunidades más centradas en el pueblo se reúnen con familiares y amigos para organizar cenas y fiestas. Para ello se montan, aprovechando el buen tiempo, mesas al aire libre, barra para sacar beneficio, música o incluso conciertos, vamos lo típico de las fiestas, con la característica de que normalmente, aunque hay de todo, no son asociaciones creadas ex profeso para la fiesta si no que fuera de ella tienen su vida, por ejemplo, es muy común que las monten las asociaciones musicales del pueblo."), "\n", React.createElement(_components.p, null, "Lo que venía a decir, el nombre que se le da este tipo de locales donde se reúne la gente es ", React.createElement(_components.em, null, "“la llar”"), ", palabra que en valenciano corresponde a hogar. Es decir, que como comentábamos antes, el hogar tradicionalmente tiene un significado distinto al que le atribuimos actualmente. Tiene el significado de comunidad, de lugar donde se reúne ésta y sus allegados. Esta comunidad puede ser o no una familia, lo importante es que haya un lugar que favorezca la comunicación entre sus miembros y por lo tanto se cree y se refuerce la comunidad."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
